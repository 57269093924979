<app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item">Cart</li>
            </ol>
        </nav>
    </div>
</section>

<section class="cart-sec pt-4 sec_padding_bottom">
    <div class="container">
        <div class="row">
            <div class="col-md-7" *ngIf="cartData?.length!=0">
                <div class="table-sec">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Video</th>
                                    <th></th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cart of cartData">
                                    <td class="ps-4" (click)="deleteItem(cart.id)"><img class="cursor_p"
                                            src="assets/images/delete-row-img.svg"></td>
                                    <td>
                                        <div class="img-wrap position-relative cursor_p"
                                            (click)="videoDetail(cart.CID)">
                                            <img class="img-fluid videoimg fit-img" [src]="baseUrl+ cart.image">
                                            <div class="overlay"></div>
                                            <img class="playbtn cursor_p" src="assets/images/cart-play-icn.svg">
                                        </div>
                                    </td>
                                    <td>{{cart.title}}</td>
                                    <td class="pe-4"><span
                                            class="d-flex align-items-center justify-content-center lh1"><img
                                                style="margin-right: 10px;"
                                                src="assets/images/cart-coin.svg">{{cart.total}} tokens</span></td>
                                </tr>

                                <!-- <tr>
                                <td class="ps-4"><img class="cursor_p" src="assets/images/delete-row-img.svg"></td>
                                <td>
                                    <div class="img-wrap position-relative cursor_p" [routerLink]="['/video-detail']">
                                        <img class="img-fluid videoimg fit-img" src="assets/images/cart-video2.png">
                                        <div class="overlay"></div>
                                        <img class="playbtn cursor_p" src="assets/images/cart-play-icn.svg">
                                    </div>
                                </td>
                                <td>Fresh Hot Meat: Jessie
                                    Saint</td>
                                <td class="pe-4"><span class="d-flex align-items-center text-nowrap justify-content-center lh1"><img style="margin-right: 10px;" src="assets/images/cart-coin.svg">10 tokens</span></td>
                              </tr> -->
                            </tbody>
                            <!-- <tbody > -->

                            <!-- </tbody> -->
                        </table>
                    </div>
                </div>
            </div>
            <!-- no data found -->
            <div class="no_data_found text-center col-md-7" *ngIf="cartData?.length==0">
                <h1>No Data found</h1>
            </div>
            <div class="col-md-5">
                <div class="payment_sec p-4 position-relative">
                    <div class="top-sec">

                        <h6 class="fs18 text-uppercase fw-medium mb-4 pb-2">Payment Method</h6>

                        <div class="method-itm">
                            <input type="radio" name="payment-method" checked>
                            <div class="inner-sec">
                                <h3 class="fw-normal fs18 mb-2">Wallet</h3>
                                <p class="d-flex align-items-center mb-3 pb-1 justify-content-between"><span
                                        class="opacity-50">Available
                                        Balance:</span> <span class="d-flex align-items-center"><img class="ms-3 me-2"
                                            src="assets/images/cart-coin.svg">{{token}} tokens
                                        <span class="info_icon cursor_p in_icon" [routerLink]="['/token-list']"> <img
                                                class="img-fluid" src="assets/images/info_icon.svg"> </span></span>
                                </p>
                                <p class="d-flex align-items-center mb-3 pb-1 justify-content-between"><span
                                        class="opacity-50">Admin
                                        Token:</span> <span class="d-flex align-items-center"><img class="ms-3 me-2"
                                            src="assets/images/cart-coin.svg">{{adminToken}} tokens
                                    </span>
                                </p>
                                <p class="d-flex align-items-center mb-3 pb-1 justify-content-between"><span
                                        class="opacity-50">Common
                                        Token:</span> <span class="d-flex align-items-center"><img class="ms-3 me-2"
                                            src="assets/images/cart-coin.svg">{{commonToken}} tokens
                                    </span>
                                </p>
                                <p class="d-flex align-items-center mb-3 pb-1 justify-content-between"><span
                                        class="opacity-50">Bonus
                                        Token:</span> <span class="d-flex align-items-center"><img class="ms-3 me-2"
                                            src="assets/images/cart-coin.svg">{{bonusToken}} tokens
                                    </span>
                                </p>
                                <p *ngIf="token<totalPrice" class="error-text fs14 pb-1">* You have insufficient tokens.
                                </p>
                            </div>
                        </div>


                        <button
                            class="btn mb-4 add-more fw-bold rounded-0 d-flex align-items-center p-0 w-100 text-white justify-content-center"
                            (click)="redirectToUrl('https://pay.chokechamber.com/#/add-token')">
                            <svg class="me-4" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <g clip-path="url(#clip0_323_1022)">
                                    <path
                                        d="M11.9377 11.8772L11.9377 20.5398C11.9214 20.7508 11.826 20.9478 11.6707 21.0916C11.5154 21.2354 11.3116 21.3152 11.0999 21.3152C10.8883 21.3152 10.6845 21.2354 10.5292 21.0916C10.3739 20.9478 10.2785 20.7508 10.2622 20.5398L10.2622 11.8772L1.59364 11.8831C1.38265 11.8668 1.18558 11.7714 1.04182 11.6161C0.898068 11.4608 0.81821 11.257 0.81821 11.0454C0.81821 10.8337 0.898067 10.6299 1.04182 10.4746C1.18558 10.3193 1.38265 10.2239 1.59364 10.2076L10.2622 10.2017L10.2563 1.53313C10.2473 1.41767 10.2624 1.30161 10.3004 1.19224C10.3385 1.08286 10.3987 0.982533 10.4774 0.897547C10.556 0.812562 10.6514 0.744754 10.7575 0.698381C10.8636 0.652007 10.9782 0.62807 11.094 0.62807C11.2098 0.62807 11.3244 0.652008 11.4305 0.698382C11.5366 0.744755 11.632 0.812563 11.7106 0.897548C11.7893 0.982533 11.8495 1.08286 11.8876 1.19224C11.9256 1.30161 11.9407 1.41767 11.9317 1.53313L11.9377 10.2017H20.6003C20.8113 10.218 21.0084 10.3133 21.1521 10.4687C21.2959 10.624 21.3757 10.8278 21.3757 11.0394C21.3757 11.2511 21.2959 11.4549 21.1521 11.6102C21.0084 11.7655 20.8113 11.8608 20.6003 11.8772L11.9377 11.8772Z"
                                        fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_323_1022">
                                        <rect width="15.5565" height="15.5565" fill="white"
                                            transform="matrix(0.707107 0.707107 0.707107 -0.707107 0 11)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            Add More Tokens
                        </button>
                    </div>

                    <div class="price-sec pt-4">
                        <h6 class="fs18 text-uppercase fw-medium mb-4 pb-1">Cart Total</h6>
                        <h6 class="d-flex mb-4 pb-1 align-items-center justify-content-between fs18 fw-normal">
                            <span>Subtotal</span><span>{{totalPrice}} tokens</span>
                        </h6>
                        <h6 class="d-flex mb-4 pb-2 align-items-center justify-content-between fs18 fw-normal">
                            <span>Total</span><span>{{totalPrice}} tokens</span>
                        </h6>
                        <button class="btn purple_btn fw-bold rounded-0 shadow-none w-100" data-bs-toggle="modal"
                            (click)="totalPrice <= token ? null : redirectToUrl('https://pay.chokechamber.com/#/add-token')"
                            [attr.data-bs-target]="totalPrice <= token ? '#buyCard' : null"
                            [disabled]="cartData?.length==0">
                            Proceed to Checkout
                        </button>

                        <!-- <button class="btn purple_btn fw-bold rounded-0 shadow-none w-100" data-bs-toggle="modal" [attr.data-bs-target]="cartData?.length!=0?'#deleteCard':null" [routerLink]="data?.isSubscription==0?['/membership']:null"
                         [disabled]="cartData?.length==0">Proceed
                            to Checkout</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="bg-blur position-absolute">
        <div class=""
    </div> -->
</section>


<app-footer></app-footer>


<div *ngIf="cartData?.length!=0" class="modal fade custom_model" id="buyCard" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body text-center pt-5">
                <!-- <img src="assets/images/studiomodule/delete_clip.svg" alt="" class="img-fluid mb-3"> -->
                <h3>Please confirm your purchase below!</h3>
                 <p class="fw-light">You want to use tokens to purchase this video ?
                </p> 
            </div>
            <div class="modal-footer gap-3">
                <button type="button" class="btn dark_btn btn60" data-bs-dismiss="modal">NO</button>
                <button type="button" class="btn red_btn shadow-none btn60" data-bs-dismiss="modal"
                    (click)="buy()">Yes</button>
            </div>
        </div>
    </div>
</div>